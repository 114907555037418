#root {
  width: 100vw;
  height: 100vh;
  background: rgb(240,176,150);
  background: linear-gradient(130deg, rgba(240,176,150,1) 0%, rgba(242,221,222,1) 100%);
  overflow: hidden;
}

.App {
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
}

.wymm-title {
  font-family: 'Great Vibes', cursive;
}

.btn-gradient {
  background: rgb(249,203,234);
  background: linear-gradient(11deg, rgba(249,203,234,1) 0%, rgba(247,212,214,1) 100%);
  background: rgb(151,150,240);
  background: linear-gradient(110deg, rgba(251,199,212,1) 10%, rgba(151,150,240,1) 90%);
  background: rgb(151,150,240);
  background: linear-gradient(110deg, rgba(251,199,212,1) 10%, rgba(246,193,212,0.5) 90%);

  border-radius: 2px;
}

.uk-card {
  border-radius: 2px;
}

.question-card {
  width: 350px;
}

.question-anim-enter {
  opacity: 0;
}
.question-anim-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.question-anim-exit {
  opacity: 1;
}
.question-anim-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.hint {

}

.hint-emphasis {
  font-weight: 700;
}

p {
  margin-block-end: 20px;
}